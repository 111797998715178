import React from 'react'
import { cssWebsites } from '../../data/popularWebsites'
import CirclesMotionX4 from './CirclesMotionX4'
import StarPurple500Icon from '@mui/icons-material/StarPurple500';
import { useBreakpoint } from './contextBreakpointMediaQ';

const clickComp = <StarPurple500Icon sx={{ fontSize: 48 }} />

const optionsCssWebsites1 = {
    clickComp,
    arrContent: cssWebsites.slice(0, 10),
    numberOfItems: 10,
    distancePixFromCenter: 150,
    itemHeight: 40,
    itemWidth: 200,
    itemHeightBetween: 5, polyLineXSpace: 10,
    styleShiftAbs: {},
}
const optionsCssWebsites2 = {
    clickComp,
    arrContent: cssWebsites.slice(10, 20),
    numberOfItems: 10,
    distancePixFromCenter: 60,
    itemHeight: 40,
    itemWidth: 200,
    itemHeightBetween: 5, polyLineXSpace: 30,
    styleShiftAbs: {},
}

const optionsCssWebsites3 = {
    clickComp,
    arrContent: cssWebsites.slice(20, 30),
    numberOfItems: 10,
    distancePixFromCenter: 60,
    itemHeight: 60,
    itemWidth: 200,
    itemHeightBetween: 5, polyLineXSpace: 10,
    styleShiftAbs: {},
}
const optionsCssWebsites4 = {
    clickComp,
    arrContent: cssWebsites.slice(30, 39),
    numberOfItems: 10,
    distancePixFromCenter: 60,
    itemHeight: 60,
    itemWidth: 200,
    itemHeightBetween: 5, polyLineXSpace: 30,
    styleShiftAbs: {},
}

const CirclesMotionX4WithCssWebsites = () => {

    const styleWrapAll = {
    }
    let listHeightPlusPx = 0
    const deviceWidth = useBreakpoint()
    let plusHeightTopBottom = 0
    optionsCssWebsites1.styleShiftAbs = {
        top: "50px"
    }
    optionsCssWebsites2.styleShiftAbs = {
        bottom: "50px"
    }
    optionsCssWebsites3.styleShiftAbs = {
        right: "200px"
    }
    optionsCssWebsites4.styleShiftAbs = {
        left: "200px"
    }

    switch (deviceWidth) {
        case "xl":
        case "lg":
            plusHeightTopBottom = 550
            optionsCssWebsites1.distancePixFromCenter = 140
            optionsCssWebsites2.distancePixFromCenter = 140
            optionsCssWebsites3.distancePixFromCenter = 105
            optionsCssWebsites4.distancePixFromCenter = 105
            optionsCssWebsites1.styleShiftAbs = {
                top: "0px"
            }
            optionsCssWebsites2.styleShiftAbs = {
                bottom: "0px"
            }
            optionsCssWebsites3.styleShiftAbs = {
                right: 0,
            }
            optionsCssWebsites4.styleShiftAbs = {
                left: 0,
            }
            break;
        case "md":
            plusHeightTopBottom = 750
            optionsCssWebsites1.distancePixFromCenter = 40
            optionsCssWebsites2.distancePixFromCenter = 40
            optionsCssWebsites3.distancePixFromCenter = 50
            optionsCssWebsites4.distancePixFromCenter = 50
            optionsCssWebsites1.styleShiftAbs = {
                top: "300px"
            }
            optionsCssWebsites2.styleShiftAbs = {
                bottom: "250px"
            }
            optionsCssWebsites3.styleShiftAbs = {
                right: 0,
            }
            optionsCssWebsites4.styleShiftAbs = {
                left: 0,
            }
            break;
        case "sm":
            plusHeightTopBottom = 0
            optionsCssWebsites1.distancePixFromCenter = 60
            optionsCssWebsites2.distancePixFromCenter = 60
            optionsCssWebsites3.distancePixFromCenter = 80
            optionsCssWebsites4.distancePixFromCenter = 80

            listHeightPlusPx = 100
            optionsCssWebsites1.styleShiftAbs = { top: "0px" }
            optionsCssWebsites2.styleShiftAbs = { bottom: 0 }
            optionsCssWebsites3.styleShiftAbs = {}
            optionsCssWebsites4.styleShiftAbs = {}

            break;
        case "xs":
            plusHeightTopBottom = 0
            optionsCssWebsites1.distancePixFromCenter = 60
            optionsCssWebsites2.distancePixFromCenter = 60
            optionsCssWebsites3.distancePixFromCenter = 80
            optionsCssWebsites4.distancePixFromCenter = 80

            listHeightPlusPx = 100
            optionsCssWebsites1.styleShiftAbs = { top: "0px" }
            optionsCssWebsites2.styleShiftAbs = { bottom: 0 }
            optionsCssWebsites3.styleShiftAbs = {}
            optionsCssWebsites4.styleShiftAbs = {}

            break;
        default:
            plusHeightTopBottom = 0
            optionsCssWebsites1.styleShiftAbs = {}
            optionsCssWebsites2.styleShiftAbs = {}
            optionsCssWebsites3.styleShiftAbs = {}
            optionsCssWebsites4.styleShiftAbs = {}
            optionsCssWebsites1.distancePixFromCenter = 100
            optionsCssWebsites2.distancePixFromCenter = 100
            optionsCssWebsites3.distancePixFromCenter = 100
            optionsCssWebsites4.distancePixFromCenter = 100
            break;
    }

    return (<>
        {[ "xl", "lg", "md" ].includes(deviceWidth) && (
            <CirclesMotionX4
                name="cssWebsites"
                arrPosOptions={[
                    [ "left", optionsCssWebsites1 ],
                    [ "right", optionsCssWebsites2 ],
                    [ "top", optionsCssWebsites3 ],
                    [ "bottom", optionsCssWebsites4 ] ]}
                plusHeightTopBottom={plusHeightTopBottom}
                isCompressed={true}
                listHeightPlusPx={listHeightPlusPx}
                styleWrapAll={styleWrapAll}
            />
        )}
        {[ "sm" ].includes(deviceWidth) && (
            <>
                <CirclesMotionX4 name="cssWebsites1" arrPosOptions={[ [ "left", optionsCssWebsites1 ], [ "right", optionsCssWebsites2 ] ]} listHeightPlusPx={listHeightPlusPx} key="cssWebsites-l" />
                <CirclesMotionX4 name="cssWebsites3" arrPosOptions={[ [ "top", optionsCssWebsites3 ] ]} key="cssWebsites-t" />
                <CirclesMotionX4 name="cssWebsites4" arrPosOptions={[ [ "bottom", optionsCssWebsites4 ] ]} key="cssWebsites-b" />
            </>
        )}
        {[ "xs" ].includes(deviceWidth) && (
            <>
                <CirclesMotionX4 name="cssWebsites1" arrPosOptions={[ [ "left", optionsCssWebsites1 ] ]} key="cssWebsites-l" />

                <CirclesMotionX4 name="cssWebsites2" arrPosOptions={[ [ "right", optionsCssWebsites2 ] ]} key="cssWebsites-r" />
                <CirclesMotionX4 name="cssWebsites3" arrPosOptions={[ [ "left", optionsCssWebsites3 ] ]} key="cssWebsites-t" />
                <CirclesMotionX4 name="cssWebsites4" arrPosOptions={[ [ "right", optionsCssWebsites4 ] ]} key="cssWebsites-b" />
            </>
        )}
    </>)
}

export default React.memo(CirclesMotionX4WithCssWebsites)

